import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Homepage';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminPanel from './components/AdminPanel';
import ListingDetail from './pages/ListingDetail';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CookiePopup from './components/CookiePopup';
import AllListings from './pages/AllListings';



function App() {
  return (
    <div className="app-container">
      <div className="main-content">
        <Router>
          <CookiePopup />
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/control" element={<AdminPanel />} />
            <Route path="/details/:id" element={<ListingDetail />} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/all-listings" element={<AllListings/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
          </Routes>
          
        </Router>
        </div>
        <Footer />
      </div>
  );  
}

export default App;
