import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import './Homepage.css';
import { Link } from 'react-router-dom';

const Homepage = () => {
  const [listings, setListings] = useState([]); // State for listings
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [filters, setFilters] = useState({
    sortBy: 'default',
    propertyType: 'default',
    listingType: 'default',
    bedrooms: 'default',
  });
  const [error, setError] = useState(null); // State for errors

  // Debugging log: Current filters
  console.log("Current Filters:", filters);

  // Fetch listings from API when filters change
  useEffect(() => {
      const fetchListings = async () => {
        setLoading(true)
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}listings/all-listings`);
          console.log("📦 Listings Fetched from API:", res.data); // 🔥 Log response data
    
          // Ensure `images` is always an array
          const formattedListings = res.data.map(listing => ({
          ...listing,
            images: Array.isArray(listing.images)
              ? listing.images
              : (typeof listing.images === 'string' ? JSON.parse(listing.images) : [])
          }));
    
          setListings(formattedListings);
          setLoading(false)
        } catch (err) {
          console.error("❌ Error fetching listings:", err);
        }
      };
      
    fetchListings();
  }, [filters]);

 

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div>
      <main className="homepage">
        {/* Hero Slider Section */}
        <section className="hero-section">
          <Slider {...settings}>
            <div className="slide-1">
              <h2>Discover Luxury Homes</h2>
              <p className='slide-txt'>Explore high-end properties in prime locations.</p>
              <button className='slide-butn'>
                <Link to="/all-listings">View Listings</Link>
              </button>
            </div>
            <div className="slide-2">
              <h2>Discover Affordable Lands For Sale</h2>
              <p className='slide-txt'>Explore affordable properties in serene locations.</p>
              <button className='slide-butn'>
                <Link to="/all-listings">View Listings</Link>
              </button>
            </div>
            <div className="slide-3">
              <h2>Find Your Dream Home</h2>
              <p className='slide-txt'>We offer homes that suit all lifestyles and budgets.</p>
              <button className='slide-butn'>
                <Link to="/all-listings">View Listings</Link>
              </button>
            </div>
            <div className="slide-4">
              <h2>Commercial Properties</h2>
              <p className='slide-txt'>Looking for office spaces or retail locations? We have you covered.</p>
              <button className='slide-butn'>
                <Link to="/all-listings">View Listings</Link>
              </button>
            </div>
          </Slider>
        </section>

        {/* Listings Preview Section */}
        <section id="listings-section" className="listings-preview">
          <h3>Featured Listings</h3>

          {/* Show loading, error or listings */}
          {loading ? (
            <p>Loading listings...</p>
          ) : error ? (
            <p className="error-message">Something went wrong. Please try again later.</p>
          ) : (
            <div className="listings-container">
              {listings.length > 0 ? (
                <>
                  {listings
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by newest
                    .slice(0, 10) // Get only the latest 10 listings
                    .map((listing) => (
                      <div className="listing-card" key={listing.id}>
                        <Link to={`/details/${listing.id}`}>
                          <div className="image-container1">
                          <img 
                            src={`${process.env.REACT_APP_API_URL}${listing.images[0].replace(/\\/g, '/')}`} 
                            alt={listing.title} 
                          />
                            <p className="listing-type">{listing.listingType}</p>
                            {/* Conditionally show "New" label */}
                            {new Date() - new Date(listing.createdAt) <= 14 * 24 * 60 * 60 * 1000 && (
                              <p className="new-label">New</p>
                            )}
                          </div>
                          <h4 className="listing-title">{listing.title}</h4>
                          <p className="listing-price">N{listing.price.toLocaleString()}NGN</p>
                          <p className="listing-details">
                            {listing.bedrooms || 0} Bed | {listing.bathrooms || 0} Bath | Parlor: {listing.livingRoomSize || 0}²m
                          </p>
                          <p className="listing-location">
                            Address: {listing.state}, {listing.town}, {listing.lga}, {listing.address}
                          </p>
                          <p className="listing-date">
                            Date Listed: {new Date(listing.createdAt).toLocaleDateString()}
                          </p>
                        </Link>
                      </div>
                    ))
                  }

                  {/* Show button only if listings exist */}
                  <div className='all-listings'>
                    <button>
                      <Link to="/all-listings">View All Listings →</Link>
                    </button>
                  </div>
                </>
              ) : (
                <p>No listings available, change filter parameter.</p>
              )}
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Homepage;
