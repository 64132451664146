import React from "react";
import "./AboutUs.css"; // Ensure you have styles for this component

const AboutUs = () => {
  return (
    <div className="about-us">
      

      <section className="about-us-content">
        <h2>Welcome to Enddy Nation Real Estate Company LTD</h2>
        <p>
          Your trusted partner in real estate solutions. Located in the heart of Owerri, Imo State, we are committed to
          providing high-quality real estate services tailored to meet the unique needs of our clients.
        </p>

        <h3>Who We Are</h3>
        <p>
          Enddy Nation Real Estate Company LTD was established with a singular goal: to make property buying, selling, 
          and leasing as seamless as possible. We understand that real estate transactions are more than just business 
          deals—they represent life-changing decisions. That's why we offer personalized services and expert guidance 
          to ensure you make informed choices every step of the way.
        </p>

        <h2>Building Foundations, Creating Homes: Why Enddynation Real Estate Company is Your Trusted Partner</h2>
        <p>
          In the ever-evolving landscape of real estate, finding a trusted partner to guide you through the process of buying, selling, or investing in properties can be a daunting task. At <strong>Enddynation Real Estate Company</strong>, we understand the significance of this journey and are committed to providing exceptional service, expertise, and personalized attention to ensure that your real estate experience is nothing short of extraordinary.
        </p>

        <h2>Our Mission: Delivering Excellence, One Property at a Time</h2>
        <p>At Enddynation Real Estate Company, our mission is simple yet profound: to deliver unparalleled real estate experiences that exceed our clients' expectations. We achieve this by:</p>
        <ul>
          <li>Fostering a culture of transparency, honesty, and integrity</li>
          <li>Employing a team of highly skilled, knowledgeable, and passionate professionals</li>
          <li>Leveraging cutting-edge technology and innovative marketing strategies</li>
          <li>Providing personalized support and guidance every step of the way</li>
        </ul>

        <h3>Our Services</h3>
        <p>We specialize in:</p>
        <ul>
          <li>Residential and Commercial Property Sales</li>
          <li>Property Leasing and Rentals</li>
          <li>Real Estate Investment Consulting</li>
          <li>Property Management</li>
          <li>Valuation and Appraisal Services</li>
        </ul>

        <h2>Why Choose Enddynation Real Estate Company?</h2>
        <p>In a crowded market, what sets us apart? Here are just a few reasons why Enddynation Real Estate Company is the go-to choice for discerning clients:</p>
        <ul>
          <li>
            <strong>Unparalleled Local Knowledge:</strong> Our team has an intimate understanding of the local market, ensuring that you receive expert guidance on the best properties, neighborhoods, and investment opportunities.
          </li>
          <li>
            <strong>Personalized Service:</strong> We take the time to understand your unique needs, preferences, and goals, tailoring our services to ensure a seamless and stress-free experience.
          </li>
          <li>
            <strong>Innovative Marketing Strategies:</strong> We employ the latest marketing techniques, including social media, virtual tours, and targeted advertising, to showcase your property to the widest possible audience.
          </li>
          <li>
            <strong>Network and Connections:</strong> Our extensive network of professionals, including contractors, lenders, and attorneys, ensures that you have access to the best resources and expertise.
          </li>
        </ul>

        <h3>Our Commitment</h3>
        <p>
          At Enddy Nation, we believe in building lasting relationships with our clients. Our core values of integrity, 
          professionalism, and excellence are at the heart of everything we do. We pride ourselves on delivering solutions 
          that not only meet but exceed our clients’ expectations.
        </p>

        <h2>Your Real Estate Journey: How We Can Help</h2>
        <p>Whether you're a first-time buyer, seasoned seller, or savvy investor, Enddynation Real Estate Company is here to guide you every step of the way. Our comprehensive services include:</p>
        <ul>
          <li>
            <strong>Buying and Selling:</strong> Expert representation for buyers and sellers, ensuring a smooth and successful transaction.
          </li>
          <li>
            <strong>Property Management:</strong> Professional management of rental properties, maximizing returns and minimizing stress.
          </li>
          <li>
            <strong>Investment Opportunities:</strong> Expert guidance on investment properties, including analysis, acquisition, and management.
          </li>
        </ul>

        <h3>Our Location</h3>
        <p>
          We are centrally located at:<br />
          <strong>The Lion Yard, World Bank, Last Roundabout, No. 2 Umuguma Road, Owerri, Imo State.</strong>
        </p>

        <p>Feel free to visit our office or get in touch with us:</p>
        <p>
          <strong>Phone:</strong> <a href="tel:08069094410">08069094410</a><br />
          <strong>Email:</strong> <a href="mailto:info@enddynationrealestate.com">info@enddynationrealestate.com</a>
        </p>

        <h2>Join the Enddynation Real Estate Company Family</h2>
        <p>
          At Enddynation Real Estate Company, we're more than just a real estate company – we're a family. We invite you to join us on your real estate journey, trusting us to provide the expertise, guidance, and support you need to achieve your goals.
        </p>
        <p>
          At Enddynation Real Estate Company, we understand that every client is unique. That's why we offer personalized service tailored to your specific needs and goals. From finding your dream home to selling your property quickly and efficiently, trust us to provide exceptional support every step of the way.
        </p>
        <p>
          <strong>Contact us today at <a href="tel:08069994410">08069994410</a> to discover why Enddynation Real Estate Company is the trusted partner for all your real estate needs.</strong>
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
