import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Footer.css'; // Custom styling

const Footer = () => {
  return (
    <footer className="footer">
      {/* New Two-Grid Section */}
      <div className="footer-grid">
        {/* Left Grid - Vision, Principles, Mission, and Core Values */}
        <div className="footer-left">
          <h2>VISION STATEMENT</h2>
          <p>
            To revolutionize the real estate industry by delivering innovative, customer-centric solutions that exceed expectations. 
            We aim to create a positive, lasting impact on our customers, employees, and the communities we serve.
          </p>

          <h3>OUR PRINCIPLES</h3>
          <ul>
            <li>Customer-Centricity</li>
            <li>Integrity</li>
            <li>Innovation</li>
            <li>Collaboration</li>
            <li>Accountability</li>
          </ul>

          <h2>MISSION STATEMENT</h2>
          <p>
            To deliver exceptional real estate services through our commitment to integrity, professionalism, and client satisfaction.
          </p>

          <h3>OUR CORE VALUES</h3>
          <ul>
            <li>Respect</li>
            <li>Empathy</li>
            <li>Excellence</li>
            <li>Sustainability</li>
            <li>Fun</li>
          </ul>
        </div>

        {/* Right Grid - Services */}
        <div className="footer-right">
          <h2>ENDDY NATION REAL ESTATE SERVICES</h2>
          <ul>
            <li>Leasing Agent</li>
            <li>Property Management</li>
            <li>Building Consultant</li>
            <li>Home Inspection</li>
            <li>Investment Management</li>
            <li>Valuation</li>
            <li>Maintenance</li>
            <li>Broker</li>
            <li>Commercial Property</li>
            <li>Real Estate Associates</li>
            <li>Realtor</li>
            <li>Marketing of Properties</li>
            <li>Leasing Consultant</li>
            <li>Borehole Drilling & Maintenance (GOE - SURVEY)</li>
            <li>Property Development</li>
          </ul>
        </div>
      </div>

      {/* Existing Footer Content */}
      <div className="footer-bottom">
        <p>&copy; 2024 Enddy Nation Real Estate Company LTD. All rights reserved.</p>
        
        <div className="social-media">
          <a href="https://facebook.com/profile.php?id=61566442072739" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x"></i>
          </a>
          <a href="https://www.instagram.com/enddy_nation_real_estate/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
