import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Custom styling

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to handle menu toggle

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu open/close state
  };

  // Close menu when backdrop is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          {/* Add the image logo here */}
          <p className='rcnumber'><strong>RC7930738</strong></p>
          <h1>Enddy Nation Real Estate LTD</h1>
        </Link>
      </div>

      <nav className="navbar">
        {/* Hamburger Icon */}
        <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span className={`bar ${isOpen ? 'bar1' : ''}`}></span>
          <span className={`bar ${isOpen ? 'bar2' : ''}`}></span>
          <span className={`bar ${isOpen ? 'bar3' : ''}`}></span>
        </div>

        {/* Navigation Links */}
        <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
          <li><Link to="/all-listings" onClick={closeMenu}>All Listings</Link></li>
          <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
          <li><Link to="/contact-us" onClick={closeMenu}>Contact Us</Link></li>
        </ul>

        {/* Backdrop */}
        {isOpen && <div className="backdrop" onClick={closeMenu}></div>}
      </nav>
    </header>
  );
};

export default Header;
