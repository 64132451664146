import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Ensure you're importing Link correctly
import './AllListings.css'; // Optional: Add your custom styles
import SortingBar from '../components/SortingBar'; // Make sure this component is available or define it as above

const AllListings = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    sortBy: 'default', // Default filter for sorting
    propertyType: 'default', // Default filter for property type
    listingType: 'default', // Default filter for listing type
    bedrooms: 'default', // Default filter for bedrooms
  });

  // Fetch listings when filters change
  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams(filters).toString();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}listings/all-listings?${query}`);

        setListings(response.data); // Set the listings data from the response
      } catch (error) {
        console.error('Error fetching listings:', error);
        // Set an error state to display a message to the user
        setError('Failed to load listings. Please try again later.');
      } finally {
        setLoading(false); // Ensure loading state is reset
      }
    };

    fetchListings();
  }, [filters]);

  // Handle changes in sorting or filtering options
  const handleSortChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters, // Update the filter state with the new values
    }));
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  return (
    <div className="listings-page">
      <h1>All Listings</h1>

      {/* Sorting Bar to sort and filter listings */}
      <section className="sorting-section">
        <SortingBar onSortChange={handleSortChange} />
      </section>

      {listings.length === 0 ? (
        <p>No listings available at the moment.</p>
      ) : (
        <div className="listings-container2">
          {listings.map((listing) => {
  // Ensure listing.images is always an array
  let images = [];

  if (typeof listing.images === 'string') {
    try {
      images = JSON.parse(listing.images);
      if (!Array.isArray(images)) images = []; // Ensure it's an array
    } catch (error) {
      console.error('Error parsing images:', error);
      images = []; // Fallback to an empty array
    }
  } else if (Array.isArray(listing.images)) {
    images = listing.images;
  }

  return (
    <div className="listing-card" key={listing.id}>
      <Link to={`/details/${listing.id}`}>
        <div className="image-container1">
          <img
            src={
              images.length > 0
                ? `${process.env.REACT_APP_API_URL}${images[0].replace(/\\/g, '/')}` 
                : 'default-image.jpg' // Fallback if no image is available
            }
            alt={listing.title}
          />
          <p className="listing-type">{listing.listingType}</p>
          {/* Show "New" label if the listing was created in the last 14 days */}
          {new Date() - new Date(listing.createdAt) <= 14 * 24 * 60 * 60 * 1000 && (
            <p className="new-label">New</p>
          )}
        </div>
        <h4>{listing.title}</h4>
        <p className="listing-price">N{listing.price.toLocaleString()}NGN</p>
        <p className="listing-details">
          {listing.bedrooms || 0} Bed | {listing.bathrooms || 0} Bath | Parlor: {listing.livingRoomSize || 0}²m
        </p>
        <p className="listing-location">
          Address: {listing.state}, {listing.town}, {listing.lga}, {listing.address}
        </p>
        <p className="listing-date">
          Date Listed: {new Date(listing.createdAt).toLocaleDateString()}
        </p>
      </Link>
    </div>
  );
})}

        </div>
      )}
    </div>
  );
};

export default AllListings;
