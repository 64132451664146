import React, { useState } from 'react';
import './EditListing.css'

const EditListing = ({ listing, onSaveChanges, onCancel }) => {
  const [editingListing, setEditingListing] = useState(listing);
  const [newImages, setNewImages] = useState([]);

  // Handle form input changes
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingListing((prev) => ({
      ...prev,
      [name]: value || '',
    }));
  };
  

  // Handle location field changes
  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setEditingListing((prev) => ({
      ...prev,
      [name]: value, // Directly update at the root level
    }));
  };
  
  // Handle image upload (new images)
  const handleImageUpload = (e) => {
    setNewImages([...newImages, ...e.target.files]);
  };

  // Handle removing an existing image
  const handleRemoveExistingImage = (index) => {
    setEditingListing((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  // Handle removing new uploaded images
  const deleteImage = (index) => {
    setNewImages((prev) => prev.filter((_, i) => i !== index));
  };

  // Save the edited listing
  const handleSaveChanges = () => {
    onSaveChanges({ ...editingListing }, [...newImages]); // Ensure fresh copies
    
  };
  
  console.log("Updated listing state:", editingListing);

  return (
    <div className="edit-listing-form">
      {/* Title */}
      <div>
        <label>Title:</label>
        <input
          name="title"
          value={editingListing.title || ''}
          onChange={handleEditInputChange}
        />
      </div>

      {/* Price */}
      <div>
        <label>Price:</label>
        <input
          type="number"
          name="price"
          value={editingListing.price || ''}
          onChange={handleEditInputChange}
        />
      </div>

      {/* Property Type */}
      <div>
        <label>Property Type:</label>
        <select
          name="propertyType"
          value={editingListing.propertyType || ''}
          onChange={handleEditInputChange}
        >
          <option value="House">House</option>
          <option value="Apartment">Apartment</option>
          <option value="Land">Land</option>
          <option value="Commercial">Commercial</option>
        </select>
      </div>

      {/* Listing Type */}
      <div>
        <label>Listing Type:</label>
        <select
          name="listingType"
          value={editingListing.listingType || ''}
          onChange={handleEditInputChange}
        >
          <option value="For Sale">For Sale</option>
          <option value="For Rent">For Rent</option>
        </select>
      </div>

      {/* Location */}
      <div>
        <label>State:</label>
        <input
          name="state"
          value={editingListing.state || ''}
          onChange={handleLocationChange}
        />
        <label>LGA:</label>
        <input
          name="lga"
          value={editingListing.lga || ''}
          onChange={handleLocationChange}
        />
        <label>Town:</label>
        <input
          name="town"
          value={editingListing.town || ''}
          onChange={handleLocationChange}
        />
        <label>Address:</label>
        <input
          name="address"
          value={editingListing.address || ''}
          onChange={handleLocationChange}
        />
      </div>

      {/* Description */}
      <div>
        <label>Description:</label>
        <textarea
          name="description"
          value={editingListing.description || ''}
          onChange={handleEditInputChange}
        />
      </div>

      {/* Dynamic Fields based on Property Type */}
      {editingListing.propertyType === 'House' || editingListing.propertyType === 'Apartment' ? (
        <>
          {/* Bedrooms */}
          <div>
            <label>Bedrooms:</label>
            <input
              type="number"
              name="bedrooms"
              value={editingListing.bedrooms || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* Bathrooms */}
          <div>
            <label>Bathrooms:</label>
            <input
              type="number"
              name="bathrooms"
              value={editingListing.bathrooms || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* Floors */}
          <div>
            <label>Floors:</label>
            <input
              type="number"
              name="floors"
              value={editingListing.floors || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* Living Room Size */}
          <div>
            <label>Living Room Size:</label>
            <input
              name="livingRoomSize"
              value={editingListing.livingRoomSize || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* House Type */}
          <div>
            <label>House Type:</label>
            <input
              name="houseType"
              value={editingListing.houseType || ''}
              onChange={handleEditInputChange}
            />
          </div>
        </>
      ) : null}

      {editingListing.propertyType === 'Land' ? (
        <div>
          {/* Land Size */}
          <label>Land Size:</label>
          <input
            name="landSize"
            value={editingListing.landSize || ''}
            onChange={handleEditInputChange}
          />
        </div>
      ) : null}

      {editingListing.propertyType === 'Commercial' ? (
        <>
          {/* Commercial Property Size */}
          <div>
            <label>Property Size:</label>
            <input
              name="propertySize"
              value={editingListing.propertySize || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* Ceiling Height */}
          <div>
            <label>Ceiling Height:</label>
            <input
              name="ceilingHeight"
              value={editingListing.ceilingHeight || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* Floor Load Capacity */}
          <div>
            <label>Floor Load Capacity:</label>
            <input
              name="floorLoadCapacity"
              value={editingListing.floorLoadCapacity || ''}
              onChange={handleEditInputChange}
            />
          </div>
          {/* Commercial Type */}
          <div>
            <label>Commercial Type:</label>
            <input
              name="commercialType"
              value={editingListing.commercialType || ''}
              onChange={handleEditInputChange}
            />
          </div>
        </>
      ) : null}

      {/* Estate */}
      <div>
        <label>Estate:</label>
        <input
          name="estate"
          value={editingListing.estate || ''}
          onChange={handleEditInputChange}
        />
      </div>

      {/* Documents (For Sale only) */}
      {editingListing.listingType === 'For Sale' && (
        <div>
          <label>Documents:</label>
          <input
            name="documents"
            value={editingListing.documents || ''}
            onChange={handleEditInputChange}
          />
        </div>
      )}

      {/* Existing Images */}
      <h4>Current Images</h4>
      <div className="existing-images">
        {editingListing.images.map((img, index) => (
          <div key={index} className="image-item">
            <img src={img} alt="Listing" className="listing-image" loading="lazy"/>
            <button
              className="delete-image"
              onClick={() => handleRemoveExistingImage(index)}
            >
              Remove Image
            </button>
          </div>
        ))}
      </div>

      {/* Upload New Images */}
      <h4>Upload New Images</h4>
      <input
        type="file"
        multiple
        onChange={handleImageUpload}
      />

      {/* Display New Image Previews */}
      <h4>New Images</h4>
      <div className="new-images-preview">
        {newImages.map((img, index) => (
          <div key={index} className="new-image-item">
            <img src={URL.createObjectURL(img)} alt="New Uploaded Preview" />
            <button
              className="delete-image"
              onClick={() => deleteImage(index)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      {/* Save Button */}
      <button
        className="save-button"
        onClick={handleSaveChanges}
      >
        Save Changes
      </button>

      {/* Cancel Button */}
      <button
        className="cancel-button"
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
  );
};

export default EditListing;
